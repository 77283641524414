import React from 'react'
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Tooltip,
} from '@mui/material'
import classnames from 'classnames'
import TableCell from '@component/Table/TableCell'
import { formatNumber, toPercentText } from '@utils/common'
import { makeStyles } from '@mui/styles'
import LuxonAdapter from '@date-io/luxon'
import { DateIOFormats } from '@date-io/core/IUtils'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    border: '1px solid #e8e8e8',
  },
  row: {
    borderSpacing: 0,
    whiteSpace: 'pre-wrap',
    '&:last-child td, &:last-child th': { borderBottom: 0 },
  },
  header: {
    whiteSpace: 'nowrap',
    backgroundColor: '#F0F6FC',
  },
  hover: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F0F6FC',
    },
  },
  noBorder: {
    '& th': {
      borderBottom: 0,
    },
  },
  empty: {
    display: 'flex',
    height: 150,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableCell: {
    wordBreak: 'normal',
  },
  link: {
    color: '#1976D2',
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  pagination: {
    borderTop: '1px solid #e8e8e8',
  },
  sortIcon: {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
  tooltip: {
    backgroundColor: '#D4E2EF',
    color: '#466CB5',
    fontSize: 14,
    padding: '8px 16px',
  },
  info: {
    marginLeft: 4,
    fontSize: 22,
    color: '#466CB5',
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
  headerSpan: {
    display: 'inline-flex',
    alignItems: 'center',
  },
}))

export interface TableConfig<T> {
  label: string
  hideHeaderLabel?: boolean
  emptyText?: string
  source?: string
  width?: number
  align?: 'center' | 'right' | 'left'
  isNumber?: boolean
  isDate?: boolean
  isPercent?: boolean
  formatKey?: keyof DateIOFormats
  className?: string
  convertItem?: (item: T, index: number) => string | JSX.Element
  onClick?: (item: T, rowIndex?: number) => void
  link?: boolean
  sort?: boolean
  showIndex?: boolean
  tooltip?: string
  [key: string]: any
}

export interface Pagination {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (newPage: number) => void
  onRowsPerPageChange: (newRowsPerPage: number) => void
}

export type Order = 'asc' | 'desc'

interface Props<T extends { [key: string]: any }> {
  data?: Array<T>
  config: Array<TableConfig<T>>
  emptyText?: string
  rowKey?: string | ((item: T, index: number) => string)
  header?: JSX.Element
  rowClassName?: string
  rowStyle?: React.CSSProperties
  onClickRow?: (item: T, index: number) => void
  onClickCell?: (row: T, cell: TableConfig<T>) => void
  tableLayout?: 'auto' | 'fixed'
  minWidth?: number
  pagination?: undefined | Pagination
  rootClass?: string
  headerClass?: string
  orderBy?: string
  order?: Order
  onSort?: (orderBy: string, order: Order) => void
  pdfPortrait?: boolean
}

const luxon = new LuxonAdapter({ locale: 'en-US' })

const TableList = <T extends { [key: string]: any }>({
  data,
  config = [],
  rowKey,
  emptyText = 'No Data',
  header,
  rowClassName,
  rowStyle,
  onClickRow,
  onClickCell,
  tableLayout = 'auto',
  pagination,
  rootClass,
  headerClass,
  orderBy,
  order,
  onSort,
  minWidth,
  ...props
}: Props<T>): JSX.Element => {
  const classes = useStyles()
  const isPuppeteer = localStorage.getItem('isPuppeteer') === 'true'
  const { pdfPortrait = false } = props
  const handleChangePage = (event: unknown, newPage: number) => {
    pagination?.onPageChange(newPage)
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    pagination?.onRowsPerPageChange(parseInt(event.target.value, 10))
  }
  const onSortClick = (item: TableConfig<T>) => {
    const { source } = item
    const isAsc = orderBy === source && order === 'asc'
    onSort?.(source || '', isAsc ? 'desc' : 'asc')
  }

  if (!data?.length && emptyText) {
    return <div className={classes.empty}>{emptyText}</div>
  }
  const lastIndex = config?.length - 1
  return (
    <TableContainer component={Paper} classes={{ root: classnames(classes.root, rootClass) }}>
      <Table style={{ minWidth: minWidth || 650, tableLayout, borderCollapse: 'separate' }} aria-label="simple table">
        <TableHead className={classnames(classes.header, headerClass)}>
          {header}
          <TableRow>
            {config.map((item, index) => {
              const defaultAlign = index !== 0 && index !== lastIndex ? 'center' : 'left'
              const { source, sort, tooltip } = item
              const align = item.align || defaultAlign
              const hideHeaderLabel = item?.hideHeaderLabel || false
              const sortActive = orderBy === source
              if (isPuppeteer && item.isPuppeteer) return null
              return (
                <TableCell
                  key={`${item.label}_${index}`}
                  width={item.width}
                  align={align}
                  isHeader
                  className={item.className}
                  emptyText={item?.emptyText}
                  isPuppeteer={isPuppeteer && pdfPortrait}
                >
                  <span className={classes.headerSpan}>
                    {sort ? (
                      <TableSortLabel
                        active={sortActive}
                        direction={sortActive ? order : 'asc'}
                        onClick={() => onSortClick(item)}
                        classes={{ icon: classes.sortIcon }}
                      >
                        {hideHeaderLabel ? '' : item.label || item.source}
                      </TableSortLabel>
                    ) : (
                      <>{hideHeaderLabel ? '' : item.label || item.source}</>
                    )}
                    {tooltip && (
                      <Tooltip title={tooltip} placement="top" classes={{ tooltip: classes.tooltip }}>
                        <InfoOutlinedIcon className={classes.info} />
                      </Tooltip>
                    )}
                  </span>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => {
            // @ts-ignore
            let key = `${row.p8Id}${index}`
            if (rowKey) {
              // @ts-ignore
              key = typeof rowKey === 'function' ? rowKey(row, index) : row[rowKey]
            }
            return (
              <TableRow
                key={key}
                onClick={() => onClickRow?.(row, index)}
                style={rowStyle}
                className={classnames(classes.row, { [classes.hover]: onClickRow }, rowClassName)}
              >
                {config.map((item, index1) => {
                  if (isPuppeteer && item.isPuppeteer) return null
                  const defaultAlign = index1 !== 0 && index1 !== lastIndex ? 'center' : 'left'
                  const align = item.align || defaultAlign
                  const { link, showIndex } = item

                  // @ts-ignore
                  let text = row[item.source]
                  if (item.convertItem) {
                    text = item.convertItem(row, index)
                  } else if (item.isNumber) {
                    // @ts-ignore
                    text = formatNumber(text)
                  } else if (item.isDate) {
                    // @ts-ignore
                    if (text) {
                      const luxonDate = luxon.date(new Date(text))
                      text = luxon.format(luxonDate, item.formatKey || 'fullDate')
                    }
                  } else if (item.isPercent) {
                    // @ts-ignore
                    text = toPercentText(text)
                  }
                  return (
                    <TableCell
                      align={align}
                      key={`${item.label}_${index1}`}
                      emptyText={item?.emptyText}
                      onClick={() => {
                        if (item.onClick) {
                          item.onClick?.(row, index)
                          return
                        }
                        onClickCell?.(row, item)
                      }}
                      isPuppeteer={isPuppeteer && pdfPortrait}
                      className={classnames(classes.tableCell, item.className, { [classes.link]: link })}
                    >
                      {showIndex ? `${index + 1}. ${text}` : text}
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={pagination.count}
          rowsPerPage={pagination.rowsPerPage}
          page={pagination.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.pagination}
        />
      )}
    </TableContainer>
  )
}

export default TableList

import axiosService from '../common/axiosService'
import CONFIG from '@constant/config'
// @ts-ignore
axiosService.setBaseUrl(CONFIG.apiHost)

const handleGetRequest = <T>(url: string, data?: any) => {
  return axiosService.get<T>(`/csm/${url}`, data)
}

const handlePostRequest = <T>(url: string, data: any) => {
  return axiosService.post<T>(`/csm/${url}`, data)
}

export const getQRBackgroundImage = (data: any) => {
  return {
    bId: data.bId,
    imageUrl: 'https://activate-dev-env.s3.us-east-2.amazonaws.com/group/c67c8611-9e4e-11ec-a185-019d2783e090/z_2.png',
  }
}

export const getAnnouncement = (data: any) => {
  return axiosService.get('/csm/Announcement/GetAnnouncements', data)
}

export const getCountFunctionalityScansByGroupId = (data: any) => {
  return axiosService.get('/csm/QrCode/CountFunctionalityScansByGroupId', data)
}
export const getScanStats = <T>(data?: any) => handleGetRequest<T>('QrCode/GetScanStats', data)

export const getGoogleRatingReport = (data: any) => {
  return axiosService.get('/csm/Report/GetGoogleRatingReport', data)
}

export const getGoogleRatingList = (data: any) => {
  return axiosService.get('/csm/Report/GetGoogleRatingList', data)
}

export const getSocialMediasByGroupId = (data: any) => {
  return axiosService.get('/csm/SocialMedia/GetSocialMediasByGroupId', data)
}

export const getTemplatesByGroupId = (data: any) => {
  return axiosService.get('/csm/Feedback/GetTemplatesByGroupId', data)
}

export const getFunctionalityTypes = (data: any) => {
  return axiosService.get('/csm/Entity/GetFunctionalityTypes', data)
}

export const getEntityTypes = <T>(data?: any) => handleGetRequest<T>('Entity/GetEntityTypes', data)

export const getEntityTypesGroupBySections = (data: any) => {
  return axiosService.get('/csm/Entity/GetEntityTypesGroupBySections', data)
}

export const getHubTypesGroupBySections = (data: any) => {
  return axiosService.get('/csm/QrCode/GetHubTypesGroupBySections', data)
}

export const getEntitiesByGroupId = <T>(data: any) => {
  return axiosService.get<T>('/csm/Entity/GetEntitiesByGroupId', data)
}

export const getPropertyDetail = <T>(data?: any) => handleGetRequest<T>('Entity/GetPropertyDetail', data)

export const getGroupAllImages = (data: any) => {
  return axiosService.get('/csm/User/GetGroupAllImages', data)
}

export const getQrStylesImages = (data: any) => {
  return axiosService.get('/csm/QrCode/GetQrStylesImages', data)
}

export const generatorQRCode = (data: any) => {
  return axiosService.post('/csm/QrCode/Generator', data)
}

export const globalAdminGenerateQr = (data: any) => {
  return axiosService.post('/csm/QrCode/GlobalAdminGenerateQr', data)
}

export const visitorLogin = <T>(data: any) => handlePostRequest<T>('User/VisitorLogin', data)

export const loginUser = <T>(data: any) => {
  return axiosService.post<T>('/csm/User/AdminLogin', data)
}

export const getGenericEntitiesForFeedback = <T>(data: any) => {
  return axiosService.get<T>('/csm/Entity/GetGenericEntitiesForFeedback', data)
}

export const getQrCode = <T>(data: any) => {
  return axiosService.get<T>('/csm/QrCode/Scan', data)
}

export const getTemplateById = <T>(data: any) => {
  return axiosService.get<T>('/csm/Feedback/GetTemplateById', data)
}

export const viewAnalytics = <T>(data: any) => {
  return axiosService.get<T>('/csm/Analytics/ViewAnalytics', data)
}

export const viewEntitiesAnalytics = <T>(data: any) => {
  return axiosService.get<T>('/csm/Analytics/ViewEntitiesAnalytics', data)
}

export const viewOverallEntityTypeAnalytics = <T>(data: any) => {
  return axiosService.get<T>('/csm/Analytics/ViewOverallEntityTypeAnalytics', data)
}

export const getCommentWordClouds = <T>(data: any) => {
  return axiosService.get<T>('/csm/Analytics/GetCommentWordClouds', data)
}

export const getEntityComments = <T>(data: any) => {
  return axiosService.get<T>('/csm/Analytics/GetEntityComments', data)
}

export const getTopicSummary = <T>(data: any) => {
  return axiosService.get<T>('/csm/Analytics/GetTopicSummary', data)
}

export const getGroups = <T>(data?: any) => {
  return axiosService.get<T>('/csm/Group/GetGroups', data)
}

export const uploadBase64 = (data: any) => {
  return axiosService.post('/csm/QrCode/UploadBase64', data)
}

export const uploadBase64AndCreateQRStyle = (data: any) => {
  return axiosService.post('/csm/QrCode/UploadBase64AndCreateQRStyle', data)
}

export const imageUrlToBase64 = (data: any) => {
  return axiosService.get('/csm/QrCode/ImageUrlToBase64', data)
}

export const uploadQrBuilderFile = (data: any) => {
  return axiosService.post('/csm/Provision/UploadQrBuilderFile', data)
}

export const getCommunicationTemplatesByGroupId = (data: any) => {
  return axiosService.get('/csm/Communication/GetTemplatesByGroupId', data)
}

export const getPurchaseTemplatesByGroupId = (data: any) => {
  return axiosService.get('/csm/Purchase/GetTemplatesByGroupId', data)
}

export const getRentalTemplatesByGroupId = (data: any) => {
  return axiosService.get('/csm/Rental/GetTemplatesByGroupId', data)
}

export const getFieldMarketingTemplates = (data: any) => {
  return axiosService.get('/csm/FieldMarketing/GetTemplatesByGroupId', data)
}

export const getEventTemplates = (data: any) => {
  return axiosService.get('/csm/Event/GetTemplatesByGroupId', data)
}

export const getRenewalTemplates = (data: any) => {
  return axiosService.get('/csm/Renewal/GetTemplatesByGroupId', data)
}

export const getMaintenanceTemplates = (data: any) => {
  return axiosService.get('/csm/Maintenance/GetTemplatesByGroupId', data)
}

export const getMaintenanceRequests = (data: any) => {
  return axiosService.get('/csm/Maintenance/GetMaintenanceRequests', data)
}

export const getFrontDeskTemplates = (data: any) => {
  return axiosService.get('/csm/FrontDesk/GetFrontDeskTemplates', data)
}

export const getSupplierCheckinTemplates = (data: any) => {
  return axiosService.get('/csm/SupplierCheck/GetTemplatesByGroupId', data)
}

export const createProperty = (data: any) => {
  return axiosService.post('/csm/Entity/CreateProperty', data)
}

export const createAccount = (data: any) => {
  return axiosService.post('/csm/Group/CreateGroup', data)
}

export const getBuildingTypes = (data: any) => {
  return axiosService.get('/csm/Entity/GetBuildingTypes', data)
}

export const editProperty = (data: any) => {
  return axiosService.post('/csm/Entity/EditProperty', data)
}

export const editAccount = (data: any) => {
  return axiosService.post('/csm/Group/UpdateGroup', data)
}

export const generateQrStyle = (data: any) => {
  return axiosService.post('/csm/QrCode/GenerateQrStyle', data)
}

export const GetRoles = (data: any) => {
  return axiosService.get('/csm/Member/GetRoles', data)
}

export const GetCities = (data: any) => {
  return axiosService.post('/csm/Geo/GetCities', data)
}

export const GetStates = (data: any) => {
  return axiosService.get('/csm/Geo/GetStates', data)
}

export const GetPropertyContacts = <T>(data: any) => {
  return axiosService.get<T>('/csm/Entity/GetPropertyContacts', data)
}

export const SaveEntity = <T>(data: any) => {
  return axiosService.post<T>('/csm/Entity/SaveEntity', data)
}

export const addPropertyContact = <T>(data: any) => {
  return axiosService.post<T>('/csm/UserAdmin/AddPropertyContact', data)
}
export const editPropertyContact = <T>(data: any) => {
  return axiosService.post<T>('/csm/UserAdmin/EditPropertyContact', data)
}
export const deletePropertyContact = (data: any) => {
  return axiosService.post('/csm/UserAdmin/DeletePropertyContact', data)
}
export const generatePropertyEntitiesAndQrs = (data: any) => {
  return axiosService.post('/csm/Entity/GeneratePropertyEntitiesAndQrs', data)
}

export const getEventRatingSummary = (data: any) => handleGetRequest<any>('Analytics/GetEventRatingSummary', data)
export const getEventComments = (data: any) => handleGetRequest('Analytics/GetEventComments', data)
export const getPageById = (data: any) => handleGetRequest('Page/GetPageById', data)
export const updatePageById = (data: any) => handlePostRequest('Page/UpdatePageById', data)
export const getEmergenciesByGroupId = (data: any) => handleGetRequest('Notice/GetEmergenciesByGroupId', data)
export const getVacanciesByGroupId = (data: any) => handleGetRequest('Vacancy/GetVacanciesByGroupId', data)
export const getDigitalQrHub = (data: any) => handleGetRequest('QrCode/GetDigitalQrHub', data)
export const saveHub = <T>(data: any) => handlePostRequest<T>('QrCode/SaveHub', data)
export const getQrHubsGroupBySections = (data: any) => handleGetRequest('QrCode/GetQrHubsGroupBySections', data)
export const getHubQrCodeInfo = (data: any) => handleGetRequest('QrCode/GetHubQrCodeInfo', data)
export const getTemplateDataById = (data: any) => handleGetRequest('QrCode/GetTemplateById', data)
export const getSimpleTemplatesByGroupId = (data: any) => handleGetRequest('Form/GetSimpleTemplatesByGroupId', data)
export const getMoveInTemplatesByGroupId = (data: any) => handleGetRequest('Move/GetMoveInTemplatesByGroupId', data)
export const getMoveOutTemplatesByGroupId = (data: any) => handleGetRequest('Move/GetMoveOutTemplatesByGroupId', data)
export const getCustomerSupportTemplatesByGroupId = (data: any) =>
  handleGetRequest('Support/GetTemplatesByGroupId', data)
export const getQrCodeDetail = (data: any) => handleGetRequest('QrCode/GetQrCodeDetail', data)
export const getQrCodeTypesGroupBySections = (data: any) =>
  handleGetRequest('QrCode/GetQrCodeTypesGroupBySections', data)
export const globalAdminUpdateQr = (data: any) => handlePostRequest('QrCode/GlobalAdminUpdateQr', data)
export const getFeedbackQrCodesEntityTypes = (data: any) =>
  handleGetRequest('QrCode/GetFeedbackQrCodesEntityTypes', data)
export const getFilterInfoByPropertyId = (data: any) => handleGetRequest('QrCode/GetFilterInfoByPropertyId', data)
export const getFunctionalityTypeDefaultTemplate = (data: any) =>
  handleGetRequest('QrCode/GetFunctionalityTypeDefaultTemplate', data)
export const getPropertyList = <T>(data: any) => handleGetRequest<T>('Entity/GetPropertyList', data)
export const getGroup = <T>(data: any) => handleGetRequest<T>('Group/GetGroup', data)
export const getQrTypesFlat = <T>(data?: any) => handleGetRequest<T>('QrCode/GetQrTypesFlat', data)
export const generatePrintKit = <T>(data?: any) => handlePostRequest<T>('QrCode/GeneratePrintKit', data)
export const getPrintKits = <T>(data?: any) => handleGetRequest<T>('QrCode/GetPrintKits', data)
export const downloadQrCodesZipFile = <T>(data?: any) => handlePostRequest<T>('QrCode/DownloadQrCodesZipFile', data)
export const archiveQrCodeByIds = <T>(data?: any) => handlePostRequest<T>('QrCode/ArchiveQrCodeByIds', data)
export const fireExtinguisherSaveTemplateFields = <T>(data?: any) =>
  handlePostRequest<T>('FireExtinguisher/SaveTemplateFields', data)
export const AEDSaveTemplateFields = <T>(data?: any) => handlePostRequest<T>('AED/SaveTemplateFields', data)
export const getQrHubIcons = <T>() => handleGetRequest<T>('QrCode/GetQrHubIcons', {})
export const getIcons = <T>(data?: any) => handleGetRequest<T>('UploadFile/GetIcons', data)
export const getNotifyRuleChoices = <T>(data?: any) => handleGetRequest<T>('QrCode/GetNotifyRuleChoices', data)
export const saveNotifyRule = <T>(data?: any) => handlePostRequest<T>('QrCode/SaveNotifyRule', data)
export const savePropertyNotifyRule = <T>(data?: any) => handlePostRequest<T>('QrCode/SavePropertyNotifyRule', data)
export const getNotifyRule = <T>(data?: any) => handleGetRequest<T>('QrCode/GetNotifyRule', data)
export const emailQrCode = <T>(data?: any) => handlePostRequest<T>('QrCode/EmailQrCode', data)
export const getBathroomServiceReport = <T>(data?: any) =>
  handleGetRequest<T>('Analytics/GetBathroomServiceReport', data)
export const getBathroomServiceTopics = <T>(data?: any) =>
  handleGetRequest<T>('Analytics/GetBathroomServiceTopics', data)
export const getBathroomServiceWordClouds = <T>(data?: any) =>
  handleGetRequest<T>('Analytics/GetBathroomServiceWordClouds', data)
export const getBathroomServiceComments = <T>(data?: any) =>
  handleGetRequest<T>('Analytics/GetBathroomServiceComments', data)
export const getPrintTemplates = <T>(data?: any) => handleGetRequest<T>('UploadFile/GetPrintTemplates', data)
export const deletePropertyContacts = <T>(data?: any) => handlePostRequest<T>('UserAdmin/DeletePropertyContacts', data)
export const getRounds = <T>(data?: any) => handleGetRequest<T>('Round/GetRounds', data)
export const saveRound = <T>(data?: any) => handlePostRequest<T>('Round/SaveRound', data)
export const getRoundById = <T>(data?: any) => handleGetRequest<T>('Round/GetRoundById', data)
export const archiveRounds = <T>(data?: any) => handlePostRequest<T>('Round/ArchiveRounds', data)
export const getRoundTypes = <T>(data?: any) => handleGetRequest<T>('Round/GetRoundTypes', data)
export const qrCodePair = <T>(data?: any) => handlePostRequest<T>('QrCode/Pair', data)
export const getPair = <T>(data?: any) => handleGetRequest<T>('QrCode/GetPair', data)
export const getReportDateRangeOptions = <T>(data?: any) => handleGetRequest<T>('Round/GetReportDateRangeOptions', data)
export const getDayRangeOptions = <T>(data?: any) => handleGetRequest<T>('Round/GetDayRangeOptions', data)
export const getComplianceOverview = <T>(data?: any) => handleGetRequest<T>('Round/GetComplianceOverview', data)
export const getRoundInspectionSummaries = <T>(data?: any) =>
  handleGetRequest<T>('Round/GetRoundInspectionSummaries', data)
export const getRoundExceptionReport = <T>(data?: any) => handleGetRequest<T>('Round/GetExceptionReport', data)
export const deleteSubscription = <T>(data?: any) => handlePostRequest<T>('Subscription/Archive', data)
export const createSubscription = <T>(data?: any) => handlePostRequest<T>('Subscription/Create', data)
export const updateSubscription = <T>(data?: any) => handlePostRequest<T>('Subscription/Update', data)
export const getQuestionOnBuilding = <T>(data?: any) => handleGetRequest<T>('Round/GetQuestionOnBuilding', data)
export const saveAEDQuestion = <T>(data?: any) => handlePostRequest<T>('Round/SaveTemplateInfoOnBuilding', data)
export const uploadRoundTemplateFile = <T>(data?: any) =>
  handlePostRequest<T>('Provision/UploadRoundTemplateFile', data)
export const resolveRoundTemplateFile = <T>(data?: any) =>
  handlePostRequest<T>('Provision/ResolveRoundTemplateFile', data)
export const uploadFileBase64 = <T>(data?: any) => handlePostRequest<T>('Provision/UploadFileBase64', data)
export const uploadEquipmentFile = <T>(data?: any) => handlePostRequest<T>('Provision/UploadEquipmentFile', data)
export const getEquipmentFilterInfo = <T>(data?: any) => handleGetRequest<T>('Equipment/GetEquipmentFilterInfo', data)
export const getMaintenanceWordClouds = <T>(data?: any) => handleGetRequest<T>('Maintenance/GetDetailWordClouds', data)
export const getSentimentsByRequestType = <T>(data?: any) =>
  handleGetRequest<T>('Maintenance/GetSentimentsByRequestType', data)
export const getMaintenanceFeedbacks = <T>(data?: any) =>
  handleGetRequest<T>('Maintenance/GetMaintenanceFeedbacks', data)
export const getFeedbackAvgScore = <T>(data?: any) => handleGetRequest<T>('Maintenance/GetFeedbackAvgScore', data)
export const getMaintenanceSummary = <T>(data?: any) => handleGetRequest<T>('Maintenance/GetFeedbackSummary', data)
export const getTenantsRequestSummaryList = <T>(data?: any) =>
  handleGetRequest<T>('Maintenance/GetTenantsRequestSummaryList', data)
export const getMaintenanceSummaries = <T>(data?: any) =>
  handleGetRequest<T>('Maintenance/GetMaintenanceFeedbackSummaries', data)
export const getMaintenanceAnalysisSummaryForGroup = <T>(data?: any) =>
  handleGetRequest<T>('Maintenance/GetMaintenanceAnalysisSummaryForGroup', data)
export const getMaintenanceAnalysisForGroup = <T>(data?: any) =>
  handleGetRequest<T>('Maintenance/GetMaintenanceAnalysisForGroup', data)
export const getFeedbackWordClouds = <T>(data?: any) => handleGetRequest<T>('Maintenance/GetFeedbackWordClouds', data)
export const getDefaultQuestions = <T>(data?: any) => handleGetRequest<T>('Equipment/GetDefaultQuestions', data)
export const saveDefaultQuestions = <T>(data?: any) => handlePostRequest<T>('Equipment/SaveDefaultQuestions', data)
export const getCustomerFeedbackSummary = <T>(data?: any) =>
  handleGetRequest<T>('Feedback/GetCustomerFeedbackSummary', data)
export const getCustomerFeedbackSummariesList = <T>(data?: any) =>
  handleGetRequest<T>('Feedback/GetCustomerFeedbackSummariesList', data)
export const getCustomerFeedbacks = <T>(data?: any) => handleGetRequest<T>('Feedback/GetCustomerFeedbacks', data)
export const uploadContactFile = <T>(data?: any) => handlePostRequest<T>('Provision/UploadContactFile', data)
export const uploadVendorFile = <T>(data?: any) => handlePostRequest<T>('Provision/UploadVendorFile', data)
export const uploadPropertyFile = <T>(data?: any) => handlePostRequest<T>('Provision/UploadPropertyFile', data)
export const getPropertyVendors = <T>(data?: any) => handleGetRequest<T>('Vendor/GetPropertyVendors', data)
export const archiveVendors = <T>(data?: any) => handlePostRequest<T>('Vendor/ArchiveVendors', data)
export const createVendor = <T>(data?: any) => handlePostRequest<T>('Vendor/CreateVendor', data)
export const editVendor = <T>(data?: any) => handlePostRequest<T>('Vendor/EditVendor', data)
export const getCheckOverview = <T>(data?: any) => handleGetRequest<T>('Vendor/GetCheckOverview', data)
export const getCheckSummary = <T>(data?: any) => handleGetRequest<T>('Vendor/GetCheckSummary', data)
export const forgetPassword = <T>(data?: any) => handlePostRequest<T>('User/ForgetPassword', data)
export const resetPassword = <T>(data?: any) => handlePostRequest<T>('User/ResetPassword', data)
export const savePins = <T>(data?: any) => handlePostRequest<T>('Pin/SavePins', data)
export const getAngusAreas = <T>(data?: any) => handleGetRequest<T>('Maintenance/GetAngusAreas', data)
export const uploadInspectionFile = <T>(data?: any) => handlePostRequest<T>('Provision/UploadInspectionFile', data)
export const getInspectionReportForGroup = <T>(data?: any) =>
  handleGetRequest<T>('Inspection/GetInspectionReportForGroup', data)
export const getInspectionAggregate = <T>(data?: any) => handleGetRequest<T>('Inspection/GetInspectionAggregate', data)
export const getQrTypeNotifyRules = <T>(data?: any) => handleGetRequest<T>('QrCode/GetQrTypeNotifyRules', data)
export const getNotifyRuleChoicesByType = <T>(data?: any) =>
  handleGetRequest<T>('QrCode/GetNotifyRuleChoicesByType', data)
export const submitReportRequest = <T>(data?: any) => handlePostRequest<T>('Report/SubmitReportRequest', data)
export const getGroupSurveyCompletion = <T>(data?: any) => handleGetRequest<T>('Survey/GetGroupSurveyCompletion', data)
export const getCustomerSurvey = <T>(data?: any) => handleGetRequest<T>('Survey/GetCustomerSurvey', data)
export const getSurveyBuilder = <T>(data?: any) => handleGetRequest<T>('Survey/GetSurveyBuilder', data)
export const saveSurveyBuilder = <T>(data?: any) => handlePostRequest<T>('Survey/SaveSurveyBuilder', data)
export const getCustomerSurveyVerify = <T>(data?: any) => handleGetRequest<T>('Survey/GetCustomerSurveyVerify', data)
export const saveCustomerSurveyVerify = <T>(data?: any) => handlePostRequest<T>('Survey/SaveCustomerSurveyVerify', data)
export const saveCustomerSurvey = <T>(data?: any) => handlePostRequest<T>('Survey/SaveCustomerSurvey', data)
export const getSurveyConnectList = <T>(data?: any) => handleGetRequest<T>('Survey/GetSurveyConnectList', data)
export const updateConnectHubItem = <T>(data?: any) => handlePostRequest<T>('Survey/UpdateConnectHubItem', data)
export const saveConnectContent = <T>(data?: any) => handlePostRequest<T>('Survey/SaveConnectContent', data)
export const generateConnectQr = <T>(data?: any) => handlePostRequest<T>('Survey/GenerateConnectQr', data)
export const downloadEquipmentsZipFile = <T>(data?: any) =>
  handlePostRequest<T>('Equipment/DownloadEquipmentsZipFile', data)
export const submitDashboardReportRequest = <T>(data?: any) =>
  handlePostRequest<T>('Report/SubmitDashboardReportRequest', data)
export const submitEmailReportRequest = <T>(data?: any) => handlePostRequest<T>('Report/SubmitEmailReportRequest', data)
export const getCheckRecordOverview = <T>(data?: any) =>
  handleGetRequest<T>('SupplierCheck/GetCheckRecordOverview', data)
export const getCheckRecordSummary = <T>(data?: any) => handleGetRequest<T>('SupplierCheck/GetCheckRecordSummary', data)
export const downloadCheckRecordSummary = <T>(data?: any) =>
  handleGetRequest<T>('SupplierCheck/DownloadCheckRecordSummary', data)
export const saveTenant = <T>(data?: any) => handlePostRequest<T>('Tenant/SaveTenant', data)
export const getSubscriptionTypes = <T>(data?: any) => handlePostRequest<T>('Subscription/GetSubscriptionTypes', data)
export const getCurrentCarouselHub = <T>(data?: any) => handleGetRequest<T>('Survey/GetCurrentCarouselHub', data)
export const saveCarousel = <T>(data?: any) => handlePostRequest<T>('Survey/SaveCarousel', data)
export const getInstallationRoundDetail = <T>(data?: any) =>
  handleGetRequest<T>('Round/GetInstallationRoundDetail', data)
export const downloadInstallationDetail = <T>(data?: any) =>
  handleGetRequest<T>('Round/DownloadInstallationDetail', data)
export const downloadGlobalPrintSetting = <T>(data?: any) =>
  handleGetRequest<T>('PrintManagement/DownloadGlobalPrintSetting', data)
export const downloadQrCodesPrintOrders = <T>(data?: any) =>
  handlePostRequest<T>('PrintManagement/DownloadQrCodesPrintOrders', data)
export const uploadTenantFile = <T>(data?: any) => handlePostRequest<T>('Provision/UploadTenantFile', data)
export const uploadResidentFile = <T>(data?: any) => handlePostRequest<T>('Provision/UploadResidentFile', data)
export const getTenantList = <T>(data?: any) => handleGetRequest<T>('Tenant/GetTenantList', data)
export const uploadGlobalPrintSettingFile = <T>(data?: any) =>
  handlePostRequest<T>('Provision/UploadGlobalPrintSettingFile', data)
export const archiveGlobalPrintSetting = <T>(data?: any) =>
  handlePostRequest<T>('PrintManagement/ArchiveGlobalPrintSetting', data)
export const savePrintProofs = <T>(data?: any) => handlePostRequest<T>('PrintManagement/SavePrintProofs', data)
export const getWoTasksByWoId = <T>(data?: any) => handleGetRequest<T>('WorkOrder/GetWoTasksByWoId', data)
export const updateTasklines = <T>(data?: any) => handlePostRequest<T>('WorkOrder/UpdateTasklines', data)
export const getPMWorkOrdersExcel = <T>(data?: any) => handleGetRequest<T>('WorkOrder/GetPMWorkOrdersExcel', data)
export const getHtmlTemplates = <T>(data?: any) => handleGetRequest<T>('Page/GetHtmlTemplates', data)
export const saveHtmlTemplate = <T>(data?: any) => handlePostRequest<T>('Page/SaveHtmlTemplate', data)
export const getGroupCOIReport = <T>(data?: any) => handleGetRequest<T>('COI/GetGroupCOIReport', data)
export const getBuildingCOIReport = <T>(data?: any) => handleGetRequest<T>('COI/GetBuildingCOIReport', data)
export const requestCOIByEmail = <T>(data?: any) => handlePostRequest<T>('Vendor/RequestCOIByEmail', data)
export const getVendorCOIByContact = <T>(data?: any) => handleGetRequest<T>('COI/GetVendorCOIByContact', data)
export const submitCOI = <T>(data?: any) => handlePostRequest<T>('COI/Submit', data)
export const reviewCOI = <T>(data?: any) => handlePostRequest<T>('COI/Review', data)
export const getCoiDetail = <T>(data?: any) => handleGetRequest<T>('COI/GetCoiDetail', data)
export const getTwoQrLocations = <T>(data?: any) => handleGetRequest<T>('QrCode/GetTwoQrLocations', data)
export const getTwoQRTypesByLocation = <T>(data?: any) => handleGetRequest<T>('QrCode/GetTwoQRTypesByLocation', data)
export const archiveQrPair = <T>(data?: any) => handlePostRequest<T>('QrCode/ArchiveQrPair', data)
export const getQrCodesByGroupId = <T>(data?: any) => handleGetRequest<T>('QrCode/GetQrCodesByGroupId', data)
export const getLocationScanStats = <T>(data?: any) => handleGetRequest<T>('QrCode/GetLocationScanStats', data)
export const getMaintenances = <T>(data?: any) => handleGetRequest<T>('Maintenance/GetMaintenances', data)
export const getEquipmentsByMSpaceId = <T>(data?: any) => handleGetRequest<T>('Equipment/GetEquipmentsByMSpaceId', data)
export const getRoundsByMSpaceId = <T>(data?: any) => handleGetRequest<T>('Round/GetRoundsByMSpaceId', data)
export const getConversationSentiments = <T>(data?: any) =>
  handleGetRequest<T>('Maintenance/GetConversationSentiments', data)
export const getBathroomComplianceOverview = <T>(data?: any) =>
  handleGetRequest<T>('Bathroom/GetComplianceOverview', data)
export const getServiceFeedbacks = <T>(data?: any) => handleGetRequest<T>('Bathroom/GetServiceFeedbacks', data)
export const getRoundInspectionsAndEquipments = <T>(data?: any) =>
  handleGetRequest<T>('Round/GetRoundInspectionsAndEquipments', data)
export const saveRoundAssigner = <T>(data?: any) => handlePostRequest<T>('Round/SaveRoundAssigner', data)
export const updateEquipmentInfo = <T>(data?: any) => handlePostRequest<T>('Equipment/UpdateEquipmentInfo', data)
export const getMoveOuts = <T>(data?: any) => handleGetRequest<T>('Move/GetMoveOuts', data)
export const getMoveOutSummary = <T>(data?: any) => handleGetRequest<T>('Move/GetMoveOutSummary', data)
export const getEngineerRoundInspectionSummaries = <T>(data?: any) =>
  handleGetRequest<T>('Round/GetEngineerRoundInspectionSummaries', data)
export const getWorkOrderComplianceOverview = <T>(data?: any) =>
  handleGetRequest<T>('WorkOrder/GetComplianceOverview', data)
export const getScheduledWorkOrders = <T>(data?: any) => handleGetRequest<T>('WorkOrder/GetScheduledWorkOrders', data)
export const getInspectionRoundReport = <T>(data?: any) => handleGetRequest<T>('Round/GetInspectionRoundReport', data)
export const getRoundSummariesList = <T>(data?: any) => handleGetRequest<T>('Round/GetRoundSummariesList', data)
export const getMemberRoles = <T>(data?: any) => handleGetRequest<T>('Member/GetMemberRoles', data)
export const getWorkOrderEquipmentTypes = <T>(data?: any) =>
  handleGetRequest<T>('WorkOrder/GetWorkOrderEquipmentTypes', data)
export const getFilterInfo = <T>(data?: any) => handleGetRequest<T>('PrintManagement/GetFilterInfo', data)
export const addPropertyTag = <T>(data?: any) => handlePostRequest<T>('Entity/AddPropertyTag', data)
export const getPropertyTags = <T>(data?: any) => handleGetRequest<T>('Entity/GetPropertyTags', data)
export const getGlobalFilterInfo = <T>(data?: any) => handleGetRequest<T>('QrCode/GetGlobalFilterInfo', data)
export const checkQrSignId = <T>(data?: any) => handleGetRequest<T>('QrCode/CheckQrSignId', data)
export const getPinActivities = <T>(data?: any) => handleGetRequest<T>('Pin/GetPinActivities', data)
export const getIncidentReports = <T>(data?: any) => handleGetRequest<T>('Incident/GetIncidentReports', data)
export const getGroupRoundSummaries = <T>(data?: any) => handleGetRequest<T>('Round/GetGroupRoundSummaries', data)
export const getGroupIncidentReports = <T>(data?: any) => handleGetRequest<T>('Incident/GetGroupIncidentReports', data)
export const getEquipmentList = <T>(data?: any) => handleGetRequest<T>('Equipment/GetEquipmentList', data)
export const getEquipmentTrends = <T>(data?: any) => handleGetRequest<T>('Equipment/GetEquipmentTrends', data)
export const getInspectionHistory = <T>(data?: any) => handleGetRequest<T>('Equipment/GetInspectionHistory', data)
export const getScanTrendData = <T>(data?: any) => handleGetRequest<T>('QrCode/GetScanTrendData', data)
export const getInspectionWithStops = <T>(data?: any) => handleGetRequest<T>('Round/GetInspectionWithStops', data)
export const getRoundInspectionsInfo = <T>(data?: any) => handleGetRequest<T>('Round/GetRoundInspectionsInfo', data)
export const sendPortalAccess = <T>(data?: any) => handlePostRequest<T>('UserAdmin/SendPortalAccess', data)
export const getCurrentPrintSettings = <T>(data?: any) =>
  handleGetRequest<T>('PrintManagement/GetCurrentPrintSettings', data)
export const getPrintSettingFilter = <T>(data?: any) =>
  handleGetRequest<T>('PrintManagement/GetPrintSettingFilter', data)
export const getBuildingInfo = <T>(data?: any) => handleGetRequest<T>('Entity/GetBuildingInfo', data)
export const getGoogleRatingOverReview = <T>(data?: any) =>
  handleGetRequest<T>('Report/GetGoogleRatingOverReview', data)
export const getPrescriptiveData = <T>(data?: any) => handleGetRequest<T>('Report/GetPrescriptiveData', data)
export const getInspectionWithStopForms = <T>(data?: any) =>
  handleGetRequest<T>('Round/GetInspectionWithStopForms', data)
export const equipmentSubmit = <T>(data?: any) => handlePostRequest<T>('Equipment/Submit', data)
export const generateInspectionPdfRequest = <T>(data?: any) =>
  handlePostRequest<T>('Round/GenerateInspectionPdfRequest', data)
export const saveProductQuestions = <T>(data?: any) => handlePostRequest<T>('Survey/SaveProductQuestions', data)
export const submitAdditionalAns = <T>(data?: any) => handlePostRequest<T>('Survey/SubmitAdditionalAns', data)
export const getIncidentInfo = <T>(data?: any) => handleGetRequest<T>('Incident/GetIncidentInfo', data)
export const submitReportBuilderRequest = <T>(data?: any) =>
  handlePostRequest<T>('Report/SubmitReportBuilderRequest', data)
export const getReportBuilder = <T>(data?: any) => handleGetRequest<T>('Report/GetReportBuilder', data)
export const emailReportBuilderPdf = <T>(data?: any) => handlePostRequest<T>('Report/EmailReportBuilderPdf', data)
export const copyRound = <T>(data?: any) => handlePostRequest<T>('Round/CopyRound', data)
export const copyQrCodes = <T>(data?: any) => handlePostRequest<T>('QrCode/CopyQrCodes', data)
export const getCheckRecordSummariesFilter = <T>(data?: any) =>
  handleGetRequest<T>('SupplierCheck/GetCheckRecordSummariesFilter', data)
export const getUpsellProducts = <T>(data?: any) => handleGetRequest<T>('Entity/GetUpsellProducts', data)
export const saveProductDetail = <T>(data?: any) => handlePostRequest<T>('Entity/SaveProductDetail', data)
export const getUpsellMatrix = <T>(data?: any) => handleGetRequest<T>('Analytics/GetUpsellMatrix', data)
export const createSolutionBuilder = <T>(data?: any) => handlePostRequest<T>('Solution/CreateSolutionBuilder', data)
export const getBuildingSolutionBuilder = <T>(data?: any) =>
  handleGetRequest<T>('Solution/GetBuildingSolutionBuilder', data)
export const getSolutionBuilderById = <T>(data?: any) => handleGetRequest<T>('Solution/GetSolutionBuilderById', data)
export const subscribeReportBuilder = <T>(data?: any) => handlePostRequest<T>('Report/SubscribeReportBuilder', data)
export const updatePrintProofNote = <T>(data?: any) =>
  handlePostRequest<T>('PrintManagement/UpdatePrintProofNote', data)
export const updateCarouselNote = <T>(data?: any) => handlePostRequest<T>('Survey/UpdateCarouselNote', data)
export const getQrHubsByGroupId = <T>(data?: any) => handleGetRequest<T>('QrCode/GetQrHubsByGroupId', data)
export const getWorkOrderTemplates = <T>(data?: any) => handleGetRequest<T>('WorkOrder/GetWorkOrderTemplates', data)
export const getExceptionReports = <T>(data?: any) => handleGetRequest<T>('Report/GetExceptionReports', data)
export const addPortfolioContact = <T>(data?: any) => handlePostRequest<T>('UserAdmin/AddPortfolioContact', data)
export const editPortfolioContact = <T>(data?: any) => handlePostRequest<T>('UserAdmin/EditPortfolioContact', data)
export const deletePortfolioContacts = <T>(data?: any) =>
  handlePostRequest<T>('Portfolio/DeletePortfolioContacts', data)
export const getVendorCOI = <T>(data?: any) => handleGetRequest<T>('COI/GetVendorCOI', data)
export const deleteBuilderSubscription = <T>(data?: any) =>
  handlePostRequest<T>('Report/DeleteBuilderSubscription', data)
export const updateBuilderSubscription = <T>(data?: any) =>
  handlePostRequest<T>('Report/UpdateBuilderSubscription', data)
export const getSurveyList = <T>(data?: any) => handleGetRequest<T>('Survey/GetSurveyList', data)
export const getBuildingActiveQrHubs = <T>(data?: any) => handleGetRequest<T>('QrCode/GetBuildingActiveQrHubs', data)
export const sendPortalSurveyAccess = <T>(data?: any) => handlePostRequest<T>('UserAdmin/SendPortalSurveyAccess', data)
export const getLegalInfo = <T>(data?: any) => handleGetRequest<T>('LeadCapture/GetLegalInfo', data)
export const saveCarouselNote = <T>(data?: any) => handlePostRequest<T>('Survey/SaveCarouselNote', data)
export const savePrintProofNote = <T>(data?: any) => handlePostRequest<T>('PrintManagement/SavePrintProofNote', data)
export const getResidentCheckOverview = <T>(data?: any) =>
  handleGetRequest<T>('LeadCapture/GetResidentCheckOverview', data)
export const getResidentCheckList = <T>(data?: any) => handleGetRequest<T>('LeadCapture/GetResidentCheckList', data)
export const getTenantUnits = <T>(data?: any) => handleGetRequest<T>('Tenant/GetTenantUnits', data)
export const getBuildingsContacts = <T>(data?: any) => handleGetRequest<T>('Entity/GetBuildingsContacts', data)
export const getDateArchiveFilterInfo = <T>(data?: any) =>
  handleGetRequest<T>('DataArchive/GetDateArchiveFilterInfo', data)
